/**
 * What's New page
 * Based on Products page
 *
 */

// IMPORTS
import React from "react";
import {graphql} from "gatsby";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import styled from "styled-components";
import moment from "moment";
import {
  SEO,
  GlobalStyle,
  NavigationHeader,
  ConsentBanner,
  ProductHeader,
  ProductIntro,
  ContentBlock,
  ContactListWithQuery,
} from "../components";
import {
  DflRegionType,
  dflRegions,
  NavigationOverlayStateType,
  elementSizes,
  ElementWrapper,
  MobileVisualWrapper,
  FullWidthImage,
  DesktopIntro,
  MobileIntro,
  MobileCaption,
  MobileTitle,
  MobileIntroText,
  WhatsNewPageResponseProps,
  whatsNewContainerTypes,
  MainContentWrapper,
  FooterWrapper,
} from "../models";

import {getDflRegionByTimezone} from "../util/util";

import {AppState} from "../store/";
import {
  setTimezoneDetectedAction,
  updateRegionAction,
  updateNavigationOverlayAction,
  updateIsNavigationInvertedAction,
  updateIsNavigationHiddenAction,
  updateUTCOffsetAction,
  updateIsHomepageAboveVideoAction,
} from "../store/system/actions";

// COMPONENT PROPERTIES
interface PropsFromState {
  selectedRegion: DflRegionType;
  timezoneDetected: boolean;
  isNavigationHidden: boolean;
  screenWidth: number;
}

const mapStateToProps = (state: AppState): PropsFromState => ({
  selectedRegion: state.system.selectedRegion,
  timezoneDetected: state.system.timezoneDetected,
  isNavigationHidden: state.system.isNavigationHidden,
  screenWidth: state.system.screenWidth,
});

interface PropsFromDispatch {
  updateRegion: (region: DflRegionType) => void;
  setTimezoneDetected: () => void;
  updateUTCOffset: (newOffset: number) => void;
  updateNavigationOverlay: (newState: NavigationOverlayStateType) => void;
  updateNavigationInverted: (isInverted: boolean) => void;
  updateIsNavigationHidden: (isNavigationHidden: boolean) => void;
  updateIsHomepageAboveVideo: (isInverted: boolean) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    updateRegion: (region: DflRegionType) => {
      return dispatch(updateRegionAction(region));
    },
    setTimezoneDetected: () => {
      return dispatch(setTimezoneDetectedAction());
    },
    updateUTCOffset: (newOffset: number) => {
      return dispatch(updateUTCOffsetAction(newOffset));
    },
    updateNavigationOverlay: (newState: NavigationOverlayStateType) => {
      return dispatch(updateNavigationOverlayAction(newState));
    },
    updateNavigationInverted: (isInverted: boolean) => {
      return dispatch(updateIsNavigationInvertedAction(isInverted));
    },
    updateIsNavigationHidden: (isNavigationHidden: boolean) => {
      return dispatch(updateIsNavigationHiddenAction(isNavigationHidden));
    },
    updateIsHomepageAboveVideo: (isInverted: boolean) => {
      return dispatch(updateIsHomepageAboveVideoAction(isInverted));
    },
  };
};

// STYLES
const PageWrapper = styled.div`
  height: 100vh;
  overflow: visible;
`;

interface WhatsNewPageProps extends PropsFromState, PropsFromDispatch {
  data: WhatsNewPageResponseProps;
}
class WhatsNewPage extends React.Component<WhatsNewPageProps> {
  constructor(props: WhatsNewPageProps) {
    super(props);
  }

  // Product category page initialization
  componentDidMount() {
    // get Timezone and utc offset once
    if (!this.props.timezoneDetected) {
      const dflRegion = getDflRegionByTimezone();
      this.props.updateRegion(dflRegion);
      if (moment && moment().utcOffset() !== null) {
        // ignore daylight savings time
        const isDayLightSavings = moment().isDST();
        const dayLightSavingsOffset = isDayLightSavings ? 1 : 0;
        this.props.updateUTCOffset(moment().utcOffset() / 60 - dayLightSavingsOffset);
      }
      this.props.setTimezoneDetected();
    }

    // set navigation to black (could be inverted, coming from landing page)
    this.props.updateNavigationInverted(false);
    this.props.updateIsHomepageAboveVideo(false);

    // unhide the menu if it is hidden
    this.props.updateIsNavigationHidden(false);
  }

  render() {
    const {data, selectedRegion, screenWidth} = this.props;
    const wp = data.wpPost.whats_new_content;

    let name;
    let description;
    let introImage;
    let introImageSize;
    let introText;

    switch (selectedRegion) {
      case dflRegions.americas:
        name = wp.americasContent[0].americasContentCloneName;
        description = wp.americasContent[0].americasContentCloneDescription;
        introImage = wp.americasContent[0].americasContentCloneIntroImage.sourceUrl;
        introImageSize = wp.americasContent[0].americasContentCloneIntroImageSize;
        introText = wp.americasContent[0].americasContentCloneIntroText;
        break;
      case dflRegions.asia:
        name = wp.asiaContent[0].asiaContentCloneName;
        description = wp.asiaContent[0].asiaContentCloneDescription;
        introImage = wp.asiaContent[0].asiaContentCloneIntroImage.sourceUrl;
        introImageSize = wp.asiaContent[0].asiaContentCloneIntroImageSize;
        introText = wp.asiaContent[0].asiaContentCloneIntroText;
        break;

      case dflRegions.emea:
      default:
        name = wp.emeaContent[0].emeaContentCloneName;
        description = wp.emeaContent[0].emeaContentCloneDescription;
        introImage = wp.emeaContent[0].emeaContentCloneIntroImage.sourceUrl;
        introImageSize = wp.emeaContent[0].emeaContentCloneIntroImageSize;
        introText = wp.emeaContent[0].emeaContentCloneIntroText;
    }

    const {
      emeaFlexibleContent,
      americasFlexibleContent,
      asiaFlexibleContent,
    } = wp;

    return (
      <PageWrapper>
        <GlobalStyle />
        {/* @ts-ignore */}
        <NavigationHeader isTextDark />
        <ConsentBanner />
        <SEO title="What's New" />

        <MainContentWrapper>
          <DesktopIntro>
            <ElementWrapper style={{marginTop: elementSizes.productPageMarginTop}}>
              <ProductHeader caption={name} title={description} />
            </ElementWrapper>

            <ElementWrapper style={{marginTop: elementSizes.productPageMarginTop}}>
              <ProductIntro imageSize={introImageSize} text={introText} image={introImage ? introImage : null} />
            </ElementWrapper>
          </DesktopIntro>

          <MobileIntro>
            <MobileVisualWrapper visualWidth={screenWidth}>
              <FullWidthImage src={introImage ? introImage : null} />
            </MobileVisualWrapper>
            <MobileCaption>{name}</MobileCaption>
            <MobileTitle>{description}</MobileTitle>
            <MobileIntroText>{introText}</MobileIntroText>
          </MobileIntro>
          {
            /**
             * Dadurch das die neue API keinen "__typename" zurückgibt muss dies anders entschieden werden.
             * Splittet "post_WhatsNewContent_EmeaFlexibleContent_ContentWithVideo" an den "_"
             * wählt das letzte Element aus ("ContentWithVideo") und entscheidet dann ob es ein Video oder Image ist.
             */
          }
          {selectedRegion === dflRegions.emea &&
            emeaFlexibleContent.map((content, i) => {
              const fieldType = content.fieldGroupName.split(/[\s_]+/);
              const type = fieldType[fieldType.length-1] === whatsNewContainerTypes.contentWithImage ? "image" : "video";
              return (
                  // @ts-ignore
                <ContentBlock
                  key={`content-${i}`}
                  type={type}
                    // @ts-ignore
                  imageUrl={type === "image" && content.imageEmea && content.imageEmea.sourceUrl}
                    // @ts-ignore
                  imageSize={type === "image" && content.imageSize}
                    // @ts-ignore
                  videoId={type === "video" && content.videoEmeaId}
                    // @ts-ignore
                  videoSize={type === "video" && content.videoSize}
                  {...content}
                />
              );
            })}

          {selectedRegion === dflRegions.americas &&
            americasFlexibleContent.map((content, i) => {
              const fieldType = content.fieldGroupName.split(/[\s_]+/);
              const type = fieldType[fieldType.length-1] === whatsNewContainerTypes.contentWithImage ? "image" : "video";
              return (
                  // @ts-ignore
                <ContentBlock
                  key={`content-${i}`}
                  type={type}
                    // @ts-ignore
                  imageUrl={type === "image" && content.imageAmericas && content.imageAmericas.sourceUrl}
                    // @ts-ignore
                  imageSize={type === "image" && content.imageSize}
                    // @ts-ignore
                  videoId={type === "video" && content.videoAmericasId}
                    // @ts-ignore
                  videoSize={type === "video" && content.videoSize}
                  {...content}
                />
              );
            })}

          {selectedRegion === dflRegions.asia &&
            asiaFlexibleContent.map((content, i) => {
              const fieldType = content.fieldGroupName.split(/[\s_]+/);
              const type = fieldType[fieldType.length-1] === whatsNewContainerTypes.contentWithImage ? "image" : "video";
              return (
                  // @ts-ignore
                <ContentBlock
                  key={`content-${i}`}
                  type={type}
                    // @ts-ignore
                  imageUrl={type === "image" && content.imageAsia && content.imageAsia.sourceUrl}
                    // @ts-ignore
                  imageSize={type === "image" && content.imageSize}
                    // @ts-ignore
                  videoId={type === "video" && content.videoAsiaId}
                    // @ts-ignore
                  videoSize={type === "video" && content.videoSize}
                  {...content}
                />
              );
            })}

          <FooterWrapper>
            <ContactListWithQuery />
          </FooterWrapper>
        </MainContentWrapper>
      </PageWrapper>
    );
  }
}

export const query = graphql`
  query ($id: Int = 1272) {
  wpPost(databaseId: {eq: $id}) {
    whats_new_content {
      emeaContent {
        emeaContentCloneName
        emeaContentCloneDescription
        emeaContentCloneIntroText
        emeaContentCloneIntroImage {
          sourceUrl
        }
        emeaContentCloneIntroImageSize
      }
      emeaFlexibleContent {
        ... on WpPost_WhatsNewContent_EmeaFlexibleContent_ContentWithImage {
          fieldGroupName
          description
          heading
          layout
          imageSize
          imageEmea {
            sourceUrl
          }
          links {
            label
            link {
              url
            }
          }
        }
        ... on WpPost_WhatsNewContent_EmeaFlexibleContent_ContentWithVideo {
          fieldGroupName
          description
          heading
          layout
          links {
            label
            link {
              url
            }
          }
          videoEmeaId
          videoSize
        }
      }
      americasContent {
        americasContentCloneName
        americasContentCloneDescription
        americasContentCloneIntroText
        americasContentCloneIntroImage {
          sourceUrl
        }
        americasContentCloneIntroImageSize
      }
      americasFlexibleContent {
        ... on WpPost_WhatsNewContent_AmericasFlexibleContent_ContentWithImage {
          fieldGroupName
          heading
          description
          layout
          links {
            label
            link {
              url
            }
          }
          imageSize
          imageAmericas {
            sourceUrl
          }
        }
        ... on WpPost_WhatsNewContent_AmericasFlexibleContent_ContentWithVideo {
          fieldGroupName
          heading
          description
          layout
          links {
            label
            link {
              url
            }
          }
          videoAmericasId
          videoSize
        }
      }
      asiaContent {
        asiaContentCloneName
        asiaContentCloneDescription
        asiaContentCloneIntroText
        asiaContentCloneIntroImage {
          sourceUrl
        }
        asiaContentCloneIntroImageSize
      }
      asiaFlexibleContent {
        ... on WpPost_WhatsNewContent_AsiaFlexibleContent_ContentWithImage {
          fieldGroupName
          heading
          description
          layout
          links {
            label
            link {
              url
            }
          }
          imageAsia {
            sourceUrl
          }
          imageSize
        }
        ... on WpPost_WhatsNewContent_AsiaFlexibleContent_ContentWithVideo {
          fieldGroupName
          heading
          description
          layout
          links {
            label
            link {
              url
            }
          }
          videoAsiaId
          videoSize
        }
      }
    }
  }
}
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WhatsNewPage);
